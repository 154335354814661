














































import type { Picture } from '@/inc/types'
import { defineComponent, PropType } from '@vue/composition-api'

interface ScrollingGalleryItem {
  title: string
  picture: Picture
  url?: string
}

interface ScrollingGallery {
  pictures?: Picture[]
  items?: ScrollingGalleryItem[]
}

import Carousel from '@/components/Carousel.vue'
import UiPicture from '@/components/ui/Picture.vue'

export default defineComponent({
  name: 'FlexibleScrollingGallery',
  components: { Carousel, UiPicture },
  props: {
    content: {
      type: Object as PropType<ScrollingGallery>,
      required: true,
    },
  },

  setup(props) {
    const items: Picture[] | ScrollingGalleryItem[] =
      props.content.pictures || props.content.items || []
    const carouselItems = items.map((item: Picture | ScrollingGalleryItem) => ({
      content: item,
      carouselKey: item.title || (item as Picture).src,
    }))

    const isPicture = (item: Picture | ScrollingGalleryItem): item is Picture =>
      Boolean((item as Picture).src)

    return { carouselItems, isPicture }
  },
})
