import { render, staticRenderFns } from "./ScrollingGallery.vue?vue&type=template&id=2faad2bf&scoped=true"
import script from "./ScrollingGallery.vue?vue&type=script&lang=ts"
export * from "./ScrollingGallery.vue?vue&type=script&lang=ts"
import style0 from "./ScrollingGallery.vue?vue&type=style&index=0&id=2faad2bf&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2faad2bf",
  null
  
)

export default component.exports